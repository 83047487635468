import api from './api'

export default {
    listarContatos(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/listarcontatos', body, config)
    },
    buscarAreas(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/listarareas', body, config)
    },
    buscarAreasTipoContato(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/listarareastipocontato', body, config)
    },
    buscarPerguntasAreasTipoContato(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/buscarPerguntasAreasTipoContato', body, config)
    },
    consultarLogEmail(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/consultarLogEmail', body, config)
    },
    buscarAppsSuporte(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/buscarAppsSuporte', body, config)
    },
    registrarContato(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/registrarcontato', body, config)
    },
    registrarAplicacaoSuporte(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/registrarAplicacaoSuporte', body, config)
    },
    desativarAplicacaoSuporte(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/desativarAplicacaoSuporte', body, config)
    },
    uploadAplicacaoSuporte(body) {
        const config = {
            headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
          };
        return api().post('/suporte/uploadaplicacaosuporte/'+body.params, body.formData, config)
    },
}