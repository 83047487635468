<template>

    

    <div style="padding:20px;">
        
        <v-card class="" elevation="" max-width="" >
            <v-card-text>

                <div class="linhaFormulario">

                    <v-col >
                        <v-select v-model="sistema" label="Sistema" item-text="nome" item-value="id" :items="listadesistema" outlined return-object hide-details dense >
                        </v-select>
                    </v-col>

                    <v-col  >
                        <v-select style="" color v-model="idareaselecionadafiltro" label="Area" item-text="nome" item-value="id" @change="()=>buscarTiposContatoFiltro({idarea:idareaselecionadafiltro.id})" :items="areasFiltro" outlined return-object hide-details dense>
                        </v-select>
                    </v-col>

                    <v-col  >
                        <v-select style="" color v-model="idtipocontatoselecionadofiltro" label="Tipo Contato" item-text="nome" item-value="id" :items="tiposcontatofiltro" outlined return-object hide-details dense>
                        </v-select>
                    </v-col>

                    <v-col>
                        <v-text-field type="" label="Digite um nome" style="margin-bottom:0px;padding-bottom:0px!important;" v-model="nomecliente" outlined hide-details dense></v-text-field>
                    </v-col>

                    <v-col  >
                        <v-select style="" color v-model="statuscliente" label="Status Cliente" item-text="nome" item-value="id" :items="listadestatuscliente" outlined return-object hide-details dense>
                        </v-select>
                    </v-col>

                    

                    

                </div>
                <div class="linhaFormulario">

                    <v-col >
                        <v-text-field type="date"  label="Dt Inicial"  v-model="dt_inicial" outlined color></v-text-field>
                    </v-col >

                    <v-col >
                        <v-text-field type="date"  label="Dt Final"  v-model="dt_final" outlined color></v-text-field>
                    </v-col >
                    <v-col >
                        <v-btn block elevation="" large color="warning" @click="limpar()" style="height:55px;" >Limpar</v-btn>
                    </v-col >
                    <v-col >
                        <v-btn block elevation="" large color="info" @click="listarContatosRealizados()"  style="height:55px;" >Buscar</v-btn>                        
                    </v-col >
                    <v-col >
                        <JsonCSV :data="atividadesProjetos" v-if="atividadesProjetos.length > 0" :name="nome_relatorio">
                        <div style="background-color:green;width:300px;height:55px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:white;border-radius:5px;">
                            Download Resultado 
                        </div>
                        </JsonCSV>
                       

                    </v-col >

                 
                </div>

             
                
            </v-card-text>
               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="display:flex;justify-content:flex-end;padding:10px;">
                    <v-btn large elevation="" color="info" @click="novaAtividade()">Novo Contato</v-btn>
                </div>
                    
                    <template>
                        <v-data-table :headers="headers" :items="listadecontatos" :items-per-page="2300" class="elevation-1" >

                           

                            <template v-slot:[`item.id`]="{ item }">
                                <a style="font-weight:bold;" @click="abrirModalContato(item)">{{item.id}}</a>
                            </template> 

                            <template v-slot:[`item.data_contato`]="{ item }">
                                {{item.data_contato | formatarDataHora}}
                            </template> 

                            <template v-slot:[`item.nao_consegui_contato`]="{ item }">
                                <div style='color:red;' >{{item.nao_consegui_contato? 'Sem Contato': ''}}</div>
                            </template> 

                            

                            
                            
                        
                        </v-data-table>
                    </template>


            </v-card-text>
               
        </v-card>







        

                
                <v-dialog v-model="exibirModalCriarAtividade" width="80vw" max-width="1000px" height="80vh">
                    <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
                        <div>
                            <v-container class="pa-8">

                                <v-row style="">

                                        <v-col class="d-flex" cols="12" sm="3" style="" >
                                            <v-autocomplete style=""
                                                label="Cliente"
                                                :items="clientes"
                                                v-model="clienteSelecionado"
                                                item-text="nome" 
                                                item-value="id"
                                            ></v-autocomplete>
                                            
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >
                                            <v-autocomplete
                                                label="Departamento Economy "
                                                :items="areas"
                                                v-model="idareaselecionada"
                                                @change="buscarAreasTipoContato({idarea:idareaselecionada})"
                                                item-text="nome" 
                                                item-value="id"
                                            ></v-autocomplete>
                                            
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >
                                            <v-autocomplete
                                                return-object
                                                label="Tipo Contato"
                                                :items="tiposcontatos"
                                                v-model="idtipocontatoselecionado"
                                                @change="buscarPerguntasAreasTipoContato({idarea:idareaselecionada,idtipocontato:idtipocontatoselecionado.id,idsistema:idsistemamodal})"
                                                item-text="nome" 
                                                item-value="id"
                                            ></v-autocomplete>
                                            
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >
                                            <v-autocomplete
                                                label="Sistema"
                                                :items="listadesistema"
                                                v-model="idsistemamodal"
                                                @change="buscarPerguntasAreasTipoContato({idarea:idareaselecionada,idtipocontato:idtipocontatoselecionado.id,idsistema:idsistemamodal})"
                                                item-text="nome" 
                                                item-value="id"
                                            ></v-autocomplete>
                                            
                                        </v-col>

                                        <v-col cols="4">
                                            <v-checkbox label="Não Consegui Contato" v-model="naoconseguicontato"></v-checkbox>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-textarea label="Observação ( Opcional )"  v-model="observacaogeral" outlined return-object hide-details dense rows="3"  >
                                            </v-textarea> 
                                        </v-col>

                                        <div style="width:100%;" v-if="naoconseguicontato">
                                            <v-col cols="12" md="12">
                                                    <label for="">Motivo do Insucesso</label>
                                                    <v-textarea label="Descrição"  v-model="motivoinsucesso" outlined return-object hide-details dense rows="3"  >
                                                    </v-textarea> 
                                            </v-col>
                                        </div> 

                                        <div style="width:100%;max-height:300px;overflow:auto;" v-if="!naoconseguicontato">

                                            <v-col cols="12" md="12" v-for="pergunta in perguntas" :key="pergunta.id">
                                                    <label for="">{{pergunta.pergunta}}</label>
                                                    <v-textarea label="Descrição"  v-model="pergunta.resposta" outlined return-object hide-details dense rows="3"  >
                                                    </v-textarea> 
                                            </v-col>

                                        </div>

                              

                                        <v-col cols="12" md="12">
                                            
                                            <v-row>
                                                    
                                                    <v-col>
                                                        <v-btn elevation="" @click="() => exibirModalCriarAtividade=false" color="error" block> Cancelar</v-btn> 
                                                    </v-col>

                                                    <v-col>
                                                        <v-btn elevation="" @click="() => registrarContato()" color="info" block>Registrar</v-btn> 
                                                    </v-col>

                                            </v-row>

                                        </v-col>

                                </v-row>
                                

                            </v-container>
                        </div>
                        
                    </div>
                </v-dialog>

                
                <v-dialog v-model="modalContatoSelecionado"  width="80vw" max-width="1000px" height="80vh">
                    <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
                        <div>
                            <v-container class="pa-8">

                                <v-row style="" v-if="contatoSelecionado">

                                        <v-col class="d-flex" cols="12" sm="2" style="" >
                                            <v-text-field type="text" label="ID" v-model="contatoSelecionado.id" dense disabled></v-text-field>                                          
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="4" style="" >
                                            <v-text-field style='color:black' type="text" label="Cliente" v-model="contatoSelecionado.cliente" dense disabled></v-text-field>                                          
                                        </v-col>
                                        
                                        <v-col class="d-flex" cols="12" sm="3" style="" >
                                            <v-text-field type="text" label="Area" v-model="contatoSelecionado.area_contato" dense disabled></v-text-field>                                          
                                        </v-col> 
                                        
                                        <v-col class="d-flex" cols="12" sm="3" style="" >
                                            <v-text-field type="text" label="Tipo" v-model="contatoSelecionado.tipo_contato" dense disabled></v-text-field>                                          
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="12" style="" >
                                            <v-text-field type="text" label="Sistema" v-model="contatoSelecionado.sistema" dense disabled></v-text-field>                                          
                                        </v-col>


                                        <v-col cols="3">
                                            <v-checkbox label="Não Consegui Contato" v-model="contatoSelecionado.nao_consegui_contato" disabled></v-checkbox>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-textarea label="Motivo Insucesso"  v-model="contatoSelecionado.motivo_insucesso" outlined return-object hide-details dense rows="3"  disabled >
                                            </v-textarea> 
                                        </v-col>
                                        <v-col cols="5">
                                            <v-textarea label="Observação ( Opcional )"  v-model="contatoSelecionado.observacao_geral" outlined return-object hide-details dense rows="3" disabled >
                                            </v-textarea> 
                                        </v-col>

                                        <h3>Perguntas x Respostas</h3>
                                        <v-col cols="12" style="height:300px;overflow:auto;">
                                            <div v-for="resposta in JSON.parse(contatoSelecionado.respostas)" :key="resposta.id">
                                                <div style="font-weight:bold;font-size:14px;color:#72767a;" >{{resposta.pergunta}}</div>
                                                <div style="padding-left:20px;padding-bottom:10px;">R: {{resposta.resposta}}</div>
                                            </div> 
                                        </v-col>


                                </v-row>
                                

                            </v-container>
                        </div>
                        
                    </div>
                </v-dialog>

    </div>

    
</template>

<script>


// import sistemas from '@/services/sistema.service.js'
// import usuarios from '@/services/usuario.service.js'
// import projetos from '@/services/prioridades.service.js'
import JsonCSV from 'vue-json-csv'
// import Prioridades from '@/services/prioridades.service.js';
import Clientes from '@/services/cliente.service.js'
// import AtividadeServico from '@/services/atividade.service.js'
// import {alertar} from '@/services/notificacao.service.js';
// import UsuarioServico from '@/services/usuario.service.js';

import SistemaServico from '@/services/sistema.service.js'
import SuporteServico from '@/services/suporte.service.js'
import dayjs from 'dayjs'
// import ClienteServico from '@/services/cliente.service.js'


// import dayjs from 'dayjs'

export default {
    name:'Atividades',
    components: {
        JsonCSV
    },
    data(){
        return{
            areasFiltro:[],
            idareaselecionadafiltro:null,
            tiposcontatofiltro:[],
            idtipocontatoselecionadofiltro:null,
            sistema:null,
            nomecliente:null,
            statuscliente:null,
            modalContatoSelecionado:false,
            contatoSelecionado:null,
            listadecontatos:[],
            headers:[
                { text: 'id', align: 'start', sortable: false, value: 'id', },
                { text: 'Nome', value: 'cliente' },
                { text: 'Sistema', value: 'sistema' },
                { text: 'Area', value: 'area_contato' },
                { text: 'Tipo', value: 'tipo_contato' },
                { text: 'Data', value: 'data_contato' },
                { text: 'Usuario', value: 'usuario_contato' },
                { text: 'Negativa', value: 'nao_consegui_contato' },
                // { text: 'Motivo', value: 'motivo_insucesso' },
                // { text: 'Obs', value: 'observacao' },
            ],
            idsistemamodal:null,
            naoconseguicontato:false,
            observacaogeral:null,
            motivoinsucesso:null,
            listadesistema:[],
            listadestatuscliente:[
                {id:0,nome:'Inativado'},
                {id:1,nome:'Ativo'}
            ],
            dt_inicial:null,
            dt_final:null,

            atividadesProjetos:[],
            atividadeselecionada:null,
            exibirModalCriarAtividade:false,
            atividadeCriar: { id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null,idcliente:0 },
            clientes:[],
            areas:[
                // {id:1, nome:'Customer Success'},
                // {id:2, nome:'Implantacao'},
                // {id:3, nome:'Suporte'},
            ],
            idareaselecionada:1,
            tiposcontatos:[
                // {id:1, nome:'Baixa Usabilidade'},
                // {id:2, nome:'Churn Feedback'},
                // {id:3, nome:'Implantação'},
                // {id:4, nome:'Motivo Inadimplência'}
            ],
            idtipocontatoselecionado:0,
            clienteSelecionado:0,
            perguntas:[],
        }
    },
    watch: {
        
    },
    methods:{

            abrirModalContato(item){
                this.modalContatoSelecionado = true
                this.contatoSelecionado = item

            },

            async registrarContato(){

                if(this.naoconseguicontato){
                    
                    if(!this.motivoinsucesso || (this.motivoinsucesso?.trim() == '' && this.motivoinsucesso?.length() < 10) ){
                        alert('Preencha um motivo válido')
                        return;
                    }

                }else{

                    if(!this.clienteSelecionado){ alert('Informe um Cliente'); return;}
                    if(!this.idareaselecionada){ alert('Informe um Departamento'); return;}
                    if(!this.idtipocontatoselecionado){ alert('Informe um Tipo de Contato'); return;}
                    if(this.idsistemamodal.id == 0){ alert('Informe um Sistema'); return;}


                    
                    let qtdeSemResposta = 0
                    for(let i = 0, len = this.perguntas.length; i < len; i++){
                        
                        let p = this.perguntas[i]
                        if(p.resposta?.trim() == '' || !p.resposta){
                            qtdeSemResposta++
                        }
                    }

                    if(qtdeSemResposta > 0){
                        alert('Responda todas as perguntas')
                        return
                    }

                }

               
                await SuporteServico.registrarContato({id_cliente:this.clienteSelecionado,
                                                        id_area:this.idareaselecionada,
                                                        id_tipo_contato:this.idtipocontatoselecionado.id,
                                                        id_sistema:this.idsistemamodal,
                                                        id_usuario:localStorage.getItem('idusuario'),
                                                        perguntas:this.perguntas,
                                                        nao_consegui_contato:this.naoconseguicontato,
                                                        motivo_insucesso:this.motivoinsucesso,
                                                        observacao_geral:this.observacaogeral,
                                                        }).then(() =>{
                                                            
                                                            this.clienteSelecionado = null
                                                            this.idareaselecionada = null
                                                            this.idtipocontatoselecionado = null
                                                            this.idsistemamodal = null
                                                            this.naoconseguicontato = null
                                                            this.motivoinsucesso = null
                                                            this.observacaogeral = null
                                                            this.perguntas = []

                                                            this.exibirModalCriarAtividade = false 

                                                            this.listarContatosRealizados()
                                                        }).catch(error =>{

                                                            this.clienteSelecionado = null
                                                            this.idareaselecionada = null
                                                            this.idtipocontatoselecionado = null
                                                            this.idsistemamodal = null
                                                            this.naoconseguicontato = null
                                                            this.motivoinsucesso = null
                                                            this.observacaogeral = null
                                                            this.perguntas = []
                                                            
                                                            alert(error)
                                                            this.exibirModalCriarAtividade = false 
                                                        })


            },

            async listarContatosRealizados(){
              
               
                SuporteServico.listarContatos({ 
                    id_sistema: this.sistema?.id,
                    nome_cliente:this.nomecliente,
                    id_status:this.statuscliente?.id,
                    data_inicial:this.dt_inicial,
                    data_final:this.dt_final,
                    id_area:this.idareaselecionadafiltro?.id,
                    id_tipo_contato:this.idtipocontatoselecionadofiltro?.id
                }).then(resp =>{ 
                    this.listadecontatos = resp.data
                
                })

            },

            async requisitarSistemas(){
                let sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
                this.listadesistema = [{id:0,nome:'Todos'}]
                this.listadesistema = this.listadesistema.concat(sistemas)
            },

            async buscarClientes(){
                await Clientes.buscar({ativo:true}).then(res =>{
                    this.clientes = [{id:0,nome:'Sem Cliente'}].concat(res.data)
                })
            },

            async buscarAreas(){
                await SuporteServico.buscarAreas({ativo:true}).then(res =>{
                    this.areas = res.data
                    this.areasFiltro = res.data
                    this.buscarAreasTipoContato({idarea:this.areas[0].id})
                })
            },

            async buscarTiposContatoFiltro(dados){
                await SuporteServico.buscarAreasTipoContato(dados).then(res =>{
                    this.tiposcontatofiltro = res.data
                    this.idtipocontatoselecionadofiltro = res.data[0]
                })
            },

            async buscarAreasTipoContato(dados){
                await SuporteServico.buscarAreasTipoContato(dados).then(res =>{
                    this.tiposcontatos = res.data
                    this.idtipocontatoselecionado = res.data[0]
                    this.idsistemamodal = {id:0,nome:'Todos'}
                    this.buscarPerguntasAreasTipoContato({...dados,idtipocontato:this.tiposcontatos[0].id})
                })
            },

             async buscarPerguntasAreasTipoContato(dados){
                await SuporteServico.buscarPerguntasAreasTipoContato(dados).then(res =>{
                    this.perguntas = res.data
                })
            },
           
            novaAtividade() {
                this.atividadeCriar={ id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null,idcliente:0 }
                this.exibirModalCriarAtividade = true;
                document.getElementById('campo-titulo')?.focus();
            },
           
            
    },

    
    async mounted(){

        let iduser = parseInt(localStorage.getItem('idusuario'))
        this.idusuario = iduser
        this.dt_inicial = dayjs().subtract(10,'day').format('YYYY-MM-DD')
        this.dt_final = dayjs().format('YYYY-MM-DD')
        
        await this.requisitarSistemas()
        await this.buscarClientes()
        await this.buscarAreas()
        

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>